<template>
    <div class="row">
            <!--<a :href="event.link" class="slide-con shadow">-->
                <!--<div class="slide-left">-->
                    <!--<img v-bind:src="event.image" alt="">-->
                <!--</div>-->
                <!--<div class="slide-right">-->
                    <!--<div v-bind:class="event.css_class">-->
                        <!--<div class="date">-->
                            <!--<h2>{{ event.day }}</h2><h3>/{{ event.month}}</h3></div>-->
                        <!--<h3>({{ event.date_of_week }})</h3>-->
                    <!--</div>-->
                    <!--<div class="evn-detail">-->
                        <!--<h1>{{ event.event_title }}</h1>-->
                        <!--<div class="evn-tag">-->
                            <!--<h4>{{ event.event_subtype }}</h4>-->
                            <!--<h4>{{ event.event_language }}</h4>-->
                        <!--</div>-->
                        <!--<div class="evn-price">-->
                            <!--<h4>-->
                                <!--{{ event.fee }}-->
                            <!--</h4>-->
                            <!--<h4>-->
                                <!--{{ event.is_included_entry_fee }}-->
                            <!--</h4>-->
                        <!--</div>-->
                    <!--</div>-->
                <!--</div>-->
            <!--</a>-->

            <div class="col-lg-4 col-md-6 col-12 non-swiper-margin" v-for="event in events">
                <div class="swiper-slide non-swiper shadow">
                    <div class="evn-date date1">
                        <div class="date">
                            <h2>{{ event.day }}</h2><h3>/{{ event.month}}</h3></div>
                        <h3>({{ event.date_of_week }})</h3>
                    </div>
                    <a :href="event.link" class="slide-con">
                        <div class="slide-top">
                            <h1>{{ event.event_title }}</h1>
                        </div>
                        <div class="slide-btm">
                            <div class="slide-left">
                                <img v-bind:src="event.image" alt="">
                            </div>
                            <div class="slide-right">
                                <div class="evn-detail">
                                    <div class="evn-tag">
                                        <h4>{{ event.event_subtype }}</h4>
                                        <h4>{{ event.event_language }}</h4>
                                    </div>
                                    <div class="evn-price">
                                        <h4>
                                            {{ event.fee }}
                                        </h4>
                                        <h4>
                                            {{ event.is_included_entry_fee }}
                                        </h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
            </div>
    </div>
</template>

<script>

    export default {
        props: ['events'],
        components: {

        },
        data() {
            return {
                dpPermanent: null,

                year: 0,
                month: 0,
                day: 0,
                last_day_of_month: 0
            }
        },
        methods: {

        },
        mounted: function(){

        }
    }
</script>