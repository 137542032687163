import axios from "axios";
import IndexLoader from "../components/IndexLoader";

var index_all_event_loader = new Vue({
    el: '.all_event_loader',
    data: {
        events: null,
        current_page: -1,
        last_page: -2,
        first_page_url: null,
        last_page_url: null,
        last_page_message: null,

        start_load_more: false,
        load_more: true,
        loading: false,

        api_index_all_event_route: null,
        csrf_field: null,
    },
    components: {
        indexloader:IndexLoader
    },
    methods: {
        onBtnLoadMore: function(click){
            click.preventDefault();
            $(this.$refs.btn_more).hide();

            var element = $('.more').remove();
            this.load_more = true;
            this.start_load_more = true;
            this.loadEvents();
        },
        loadEvents: function(){
            this.loading = true;
            var route = this.api_index_all_event_route;
            if(this.current_page === null && this.last_page === null){
                route = route+'?page=1';
            } else {
                route = route+'?page='+(this.current_page+1);
            }

            if (this.current_page !== this.last_page) {
                this.startLoading();
                if(this.load_more === true) {
                    axios.post(route, {
                        csrf_token: this.csrf_field,
                    })
                        .then((response) => {
                            this.endLoading();

                            this.current_page = response.data.current_page;
                            this.last_page = response.data.last_page;

                            if (this.current_page === 1) {
                                this.last_page_message = response.data.last_page_message;
                            }
                            if (response.data.current_page === response.data.last_page) {
                                this.load_more = false;
                            }

                            //update events, component will auto generate new events on frontend
                            if (this.events === null) {
                                this.events = JSON.parse(response.data.entries);
                                console.log(this.events);
                            } else {
                                this.events = this.events.concat(JSON.parse(response.data.entries));
                            }

                            this.loading = false;
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                    ;
                }
            } else {
                this.load_more = false;

                this.appendEndMessage();
            }
        },
        startLoading: function(){
            $('.all_event_loader').find('.row').append(
                '<div class="lds-grid-wrapper">' +
                '<div class="lds-grid"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>' +
                '</div>'
            );
        },
        endLoading: function(){
            $('.lds-grid-wrapper').remove();
        },
        appendEndMessage: function(){
            $('.all_event_loader').find('.row').append(
                '<div class="col-lg-12 col-md-12 col-sm-12 month-event shadow" style="display: flex; justify-content: center;">\n' +
                '                    <div class="evn-detail" style="display: flex;justify-content: center;align-items:center;height: 100px;">\n' +
                '                        <div class="evn-tag">\n' +
                '                            <h4>'+ this.last_page_message +'</h4>\n' +
                '                        </div>\n' +
                '                    </div>\n' +
                '</div>'
            );
        }
    },
    mounted: function (){
        // this.events = JSON.parse(this.$el.getAttribute('data_events'));

        this.api_index_all_event_route = this.$el.getAttribute('api_index_all_event_route');
        this.csrf_field = this.$el.getAttribute('csrf_token');

        this.loadEvents();
    }
});

$(window).scroll(function() {
    if($(window).scrollTop() + $(window).height() === $(document).height()) {
        if(index_all_event_loader.load_more === true && index_all_event_loader.start_load_more === true && index_all_event_loader.loading === false){
            console.log('scroll load');
            index_all_event_loader.loadEvents();
        }
    }
});